<template>
  <div class="q" v-title :data-title="title">
    <div class="xinxis">
      <div class="xlist">
        <p>总收录</p>
        <span>{{total}}人</span>
      </div>
       <div class="xlist">
        <p>待审核</p>
        <span>{{examine}}人</span>
      </div>
       <div class="xlist">
        <p>诚信商</p>
        <span>{{chain}}人</span>
      </div>
    </div>
<!--     <li class="list">
      <div class="left">
        <p class="title-p">总收录</p>
        <p class="title-s">
          <span class="zrs">{{ total }}</span> 人
        </p>
      </div>
      <div class="reg">
        <div class="box">
          <i class="iconfont icon-contract_hetong"></i>
        </div>
      </div>
    </li>
    <li class="list">
      <div class="left">
        <p class="title-p">待审核</p>
        <p class="title-s">
          <span class="dsh">{{ examine }}</span> 人
        </p>
      </div>
      <div class="reg">
        <div class="box">
          <i class="iconfont icon-visitors_laifangzhe"></i>
        </div>
      </div>
    </li>
    <li class="list">
      <div class="left">
        <p class="title-p">诚信商家</p>
        <p class="title-s">
          <span class="yls">{{ chain }}</span> 个
        </p>
      </div>
      <div class="reg">
        <div class="box">
          <i class="iconfont icon-safety_anquan"></i>
        </div>
      </div>
    </li> -->

    <li class="list">
      <div class="left">
        <p class="title-p">当前系统版本</p>
        <p class="banb">
          {{oldbanb}} ----> 最新版：{{banb}}<!-- <button class="banbeng">发现新版 前往更新</button> -->
        </p>
        <a :href="downloadurl" class="download" v-if="download">下载新版</a>
      </div>
      <div class="reg">
        <div class="box">
          <i class="iconfont icon-question_wodewenda"></i>
        </div>
      </div>
    </li>
    <li class="list">
      <div class="left">
        <p class="title-p">版权声明</p>
        <p class="title-s">由须小豆网络制作</p>
      </div>
      <div class="reg">
        <div class="box">
          <i class="iconfont icon-question_wodewenda"></i>
        </div>
      </div>
    </li>
    <li class="list">
      <div class="left">
        <p class="title-p">官方Q群</p>
        <p class="title-s" style="font-size:14px">小K网制作</p>
      </div>
      <div class="reg">
        <div class="box">
          <i class="iconfont icon-question_wodewenda"></i>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import qs from 'qs'
import { Toast } from 'vant'
export default {
  data () {
    return {
      total: 0,
      examine: 0,
      chain: 0,
      /* 版本 */
      banb: '2.0.0',
      oldbanb: '2.1.4',
      /* 下载链接 */
      downloadurl: '',
      download: false,
      title: '',
      /* 授权时间，后端API */
      apiurl: '',
      shoutime: ''
    }
  },
  methods: {
    /* 获取信息总览信息 */
    async getview () {
      const postData = {}
      postData.token = localStorage.getItem('xdnet_token')
      const { data: over } = await this.$http.post(
        '/admin/overview',
        qs.stringify(postData)
      )
      /* 数据判断逻辑 */
      if (over.error_code === 1001) {
        this.total = over.data.total
        this.examine = over.data.examine
        this.chain = over.data.chain
      } else if (over.data === 'token不存在数据库中--->已拦截') {
        localStorage.removeItem('xdnet_token')
        this.$router.push('/admin')
      } else if (over.error_code === 1007) {
        Toast('请将数据填写完整')
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    /* 获取最新版本说明 */
    async getbb () {
      const { data: over } = await this.$http.post('http://shou.api.xudounet.com/tga/bb')
      /* 数据判断逻辑 */
      if (over.error_code === 1001) {
        this.banb = over.data.banb
        this.downloadurl = over.data.downloadUrl
        if (this.oldbanb !== over.data.banb) {
          this.download = true
        }
      } else if (over.error_code === 1002) {
        this.$router.push('/sos')
      } else {
        Toast('未知错误' + over.error_code)
      }
    },
    /* 获取后端接口api链接 */
    getapi () {
      this.$http.get('http://' + location.host + '/static/apiurl.json').then((res) => {
        this.apiurl = res.data.url
        this.getshou()
      })
    },
    /* 获取授权到期时间 */
    async getshou (a) {
      const postdata = {}
      postdata.url = this.apiurl.replace('http://', '')
      const { data: over } = await this.$http.post('http://shou.api.xudounet.com/tga/if', window.btoa(JSON.stringify(postdata)))
      this.shoutime = over.time
    }
  },
  created () {
    this.getview()
    this.getbb()
    this.getapi()
    this.title = JSON.parse(localStorage.getItem('xdnet_con')).tilte + '-后台信息总览'
  }
}
</script>

<style lang="less" scoped>
.xinxis{
  width: 90%;
  border-radius: 6px;
  background-color: white;
  display: flex;
  margin: 10px auto;
  .xlist{
    width: 100%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    p{
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    span{
            font-size: 14px;
      font-weight: 600;
      color: rgb(95, 95, 95);
    }
  }
}
.download{
    width: 70px;
    height: 26px;
    background-color: rgb(49, 142, 255);
    color: white;
    line-height: 26px;
    text-align: center;
    display: block;
    border-radius: 6px;
    margin-top: 10px;
    font-size: 12px;
}
.banb{
    font-size: 12px;
    margin-top: 10px;
}
.q {
  width: 100%;
  height: 100%;
  background-color: #fafbfe;
  overflow: hidden;
}
.list {
  width: 90%;
  /* height: 120px; */
  background-color: white;
  border-radius: 6px;
  margin: 10px auto;
  display: flex;
}
.left {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.title-p {
  font-size: 14px;
  color: #575757;
}
.title-s {
  font-size: 18px;
  color: #363636;
  font-weight: 700;
  margin-top: 20px;
}
.reg {
  width: 100px;
  height: 100px;
  position: relative;
}
.box {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  right: 20px;
  /* background-color: #8eb1ff; */
  border-radius: 10px;
  text-align: center;

  line-height: 50px;
}
.box i {
  font-size: 40px !important;
}
.banbeng {
  border: none;
  border-radius: 7px;
  background-color: rgb(72, 127, 255);
  box-shadow: 2px 2px 4px rgb(217, 229, 255);
  height: 23px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
